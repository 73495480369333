var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _c(
      "div",
      { staticClass: "item__title" },
      [_vm._t("icon"), _vm._v(" " + _vm._s(_vm.title) + " ")],
      2
    ),
    _c(
      "div",
      { staticClass: "item__date" },
      [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.address) } }),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.workTime) } }),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }