var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "map" },
    [
      _vm._l(_vm.map, function (item, index) {
        return _c("div", { key: index })
      }),
      _c(
        "yandex-map",
        {
          attrs: {
            coords: _vm.map[0],
            zoom: 16,
            "scroll-zoom": false,
            controls: ["zoomControl"],
          },
        },
        _vm._l(_vm.map, function (item, index) {
          return _c("ymap-marker", {
            key: index,
            attrs: {
              coords: item,
              "marker-id": index,
              icon: _vm.markerIcon[index],
            },
          })
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }