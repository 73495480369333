<template>
  <div class="item">
    <div class="item__title">
      <slot name="icon" />
      {{ title }}
    </div>
    <div class="item__date">
      <p v-html="address" />
      <p v-html="workTime" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAdress",
  props: {
    title: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
    workTime: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &__title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;

    @media (min-width: $screen-mini) {
      font-size: 26px;
    }

    svg {
      margin-right: 10px;
    }
  }

  &__date {
    font-family: Rubik;
    font-size: 9px;
    font-weight: 300;
    color: #918f8f;
    ::v-deep {
      span {
        color: #fff;
      }
      p {
        line-height: 1.7;
      }
      .phone {
        display: inline-block;
      }
      a {
        color: inherit;
        font: inherit;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
    @media (min-width: $screen-mini) {
      font-size: 14px;
    }
  }
}
</style>
