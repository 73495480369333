var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "21",
        viewBox: "0 0 16 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.96552 21C7.96552 21 15.931 12.3648 15.931 7.96552C15.931 3.56628 12.3647 0 7.96552 0C3.56628 0 0 3.56628 0 7.96552C0 12.3648 7.96552 21 7.96552 21ZM7.96589 11.5864C9.96555 11.5864 11.5866 9.96532 11.5866 7.96567C11.5866 5.96602 9.96555 4.34498 7.96589 4.34498C5.96624 4.34498 4.3452 5.96602 4.3452 7.96567C4.3452 9.96532 5.96624 11.5864 7.96589 11.5864Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }