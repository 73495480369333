<template>
  <div class="map">
    <div v-for="(item, index) in map" :key="index" class="" />
    <yandex-map
      :coords="map[0]"
      :zoom="16"
      :scroll-zoom="false"
      :controls="['zoomControl']"
    >
      <ymap-marker
        v-for="(item, index) in map"
        :key="index"
        :coords="item"
        :marker-id="index"
        :icon="markerIcon[index]"
      />
    </yandex-map>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MapBlock",
  data: () => ({
    coords: [],
    markerIcon: [
      {
        layout: "default#imageWithContent",
        imageHref: require("../../assets/images/icons/locationRed.svg"),
        imageSize: [33, 33],
        imageOffset: [-15, -20],
        content: `<div style="color: #18181B; width: 140px; margin-left: 35px; margin-top: -35px">
                    <p style="font-size: 16px; margin-bottom: 0px;">ЖК MAINSTREET</p>
                    <p style="font-family: Rubik; font-size: 12px; margin-top: 5px;">ул. Ивана Франко, 8</p>
                  </div>`,
        contentOffset: [0, 15],
      },
      {
        layout: "default#imageWithContent",
        imageHref: require("../../assets/images/icons/locationBlue.svg"),
        imageSize: [33, 33],
        imageOffset: [-15, -20],
        content: `<div style="color: #18181B; width: 140px; margin-left: -135px; margin-top: -35px">
                    <p style="font-size: 16px; margin-bottom: 0px;">ОФИС ПРОДАЖ</p>
                    <p style="font-family: Rubik; font-size: 12px; margin-top: 5px;">ул. Ивана Франко, 6</p>
                  </div>`,
        contentOffset: [0, 15],
      },
    ],
  }),
  computed: {
    ...mapState({
      mapContentState: (state) => state.map,
    }),
    map() {
      if (this.mapContentState.length === 0) {
        return [[], []];
      }
      const coords = this.coords;
      const officeCoord = [];
      const buildingCoord = [];
      officeCoord.push(
        this.mapContentState[0].addr_lat,
        this.mapContentState[0].addr_lon
      );
      buildingCoord.push(
        this.mapContentState[1].addr_lat,
        this.mapContentState[1].addr_lon
      );
      coords.push(officeCoord, buildingCoord);
      return coords;
    },
  },
  watch: {
    test() {
      return this.mapContentState;
    },
  },
};
</script>

<style scoped lang="scss">
.map {
  height: 567px;

  @media (max-width: $screen-md) {
    height: 355px;
  }

  @media (max-width: $screen-xs) {
    height: 245px;
  }
  .ymap-container {
    height: 100%;
  }
}
</style>
